// TODO Load Dark or light theme
import "../styles/main.scss";

import { Expression } from "aurelia-binding";
import { Aurelia, Factory, inject, Origin, PLATFORM, ViewLocator, ViewStrategy } from "aurelia-framework";
import { I18N, TCustomAttribute } from "aurelia-i18n";
import * as LogManager from "aurelia-logging";
import { AureliaValidationConfiguration, ValidationMessageProvider } from "aurelia-validation";
import { default as I18NextHttpBackend } from "i18next-http-backend";
import Config from "config";
import CustomViewStrategy from "core/custom-view-strategy";
import { CustomLogAppender } from "core/custom-log-appender";
import Logger from "core/logger";
import { configure as configureTypedBindingPlugin } from "core/typed-binding-configs";
import val from "core/val";
import { ErrorManager } from "error-management/error-manager";
import { GlobalErrorCatcher } from "error-management/global-error-catcher";
import browserHelper from "helpers/browserHelper";
import { default as routerHelper } from "helpers/routerHelper";
import { ViewModeHelper } from "helpers/view-mode-helper";
import { SettingRepository } from "repositories/setting-repository";
import applicationInsightsService from "services/applicationInsightsService";
import securityService from "services/securityService";
import userService from "services/userService";
import { LocalizationHelper } from "helpers/localization-helper";

// --------------------
// Aurelia entry-point
// --------------------
export async function configure(aurelia: Aurelia): Promise<void> {
    const main: Main = aurelia.container.get(Main);
    await main.start();
}

// --------------------
// Main
// --------------------
@inject(Aurelia, ViewModeHelper, Factory.of(CustomViewStrategy), GlobalErrorCatcher, ErrorManager, SettingRepository, LocalizationHelper, I18N)
export class Main {
    constructor(
        private readonly aurelia: Aurelia,
        private readonly viewModeHelper: ViewModeHelper,
        private readonly customViewStrategyFactory: (origin: Origin) => ViewStrategy,
        private readonly globalErrorCatcher: GlobalErrorCatcher,
        private readonly errorManager: ErrorManager,
        private readonly settingRepository: SettingRepository,
        private readonly localizationHelper: LocalizationHelper,
        private readonly i18n: I18N
    ) {
    }

    public async start(): Promise<void> {
        // Make the container instance globally reachable through Container.instance.
        this.aurelia.container.makeGlobal();

        this.setupViewLocation();
        this.setupViewMode();

        this.configureLogging();

        // Configure typed binding and observable
        configureTypedBindingPlugin();

        // Error handling setup should only be call once the logger is configured
        this.globalErrorCatcher.init();
        this.errorManager.init();

        this.parseQueryStringParameters();

        // Initialize & start Aurelia.
        this.aurelia.use
            .standardConfiguration()
            .plugin(PLATFORM.moduleName("aurelia-dialog"))
            .plugin(PLATFORM.moduleName("aurelia-swipeout"))
            .plugin(PLATFORM.moduleName("aurelia-validation"), (config: AureliaValidationConfiguration) => this.configureValidation(config))
            .plugin(PLATFORM.moduleName("aurelia-i18n"), this.configureLocalization)

            .feature(PLATFORM.moduleName("attributes/index"))
            .feature(PLATFORM.moduleName("components/index"))
            .feature(PLATFORM.moduleName("converters/index"));
        // Note: The "ag-grid-aurelia" plugin is not initialized here because we want to load it only on demand. See AgGridHelper.

        await this.aurelia.start();

        this.onAureliaStarted();
    }

    private parseQueryStringParameters(): void {
        // Company Tag
        const companyTag: string | null = routerHelper.getQueryStringNoEncode("companyTag");
        if (companyTag) {
            this.settingRepository.setCompany(companyTag);
        }

        // User Code
        const userCode: string | null = routerHelper.getQueryStringNoEncode("userCode");
        if (userCode) {
            this.settingRepository.setLanguage(userCode);
        }

        // Culture
        const culture: string | null = routerHelper.getQueryStringNoEncode("culture");
        if (culture) {
            this.settingRepository.setLanguage(culture);
        }
    }

    private setupViewLocation(): void {
        // Use a custom view strategy to locate views for each view-model.
        ViewLocator.prototype.createFallbackViewStrategy = (origin: Origin): ViewStrategy => {
            return this.customViewStrategyFactory(origin);
        };
    }

    private setupViewMode(): void {
        // NOTE: When in hosted mode, the view mode should always be set to "desktop" by the C# host.

        // Set up the view port based on the current view mode.
        this.viewModeHelper.initViewport();
    }

    private configureLogging(): void {
        LogManager.addAppender(this.aurelia.container.get(CustomLogAppender));

        if (Config.debug || window.location.href.match(/.*?(\?|&)debug=true.*?/i)) {
            LogManager.setLevel(LogManager.logLevel.debug);
        } else {
            LogManager.setLevel(LogManager.logLevel.error);
        }
    }

    /* tslint:disable:only-arrow-functions */
    private configureValidation(config: AureliaValidationConfiguration): void {
        const i18nCapture: I18N = this.i18n;

        // TODO: Must cast to any for now because parser is private in ValidationMessageProvider. It was fixed and changed to public in 2018-04 but it is not in the latest aurelia-validation release.
        // See https://github.com/aurelia/validation/issues/464
        (ValidationMessageProvider.prototype as any).getMessage = function(key: string): Expression {
            const translation: string = i18nCapture.tr(key);
            return this.parser.parse(translation);
        };
        (ValidationMessageProvider.prototype as any).getDisplayName = function(propertyName: string, displayName?: string | null | (() => string)): string {
            if (displayName !== null && displayName !== undefined) {
                return (displayName instanceof Function) ? displayName() : displayName;
            }
            return i18nCapture.tr(propertyName);
        };
    }
    /* tslint:enable:only-arrow-functions */

    private configureLocalization(instance: I18N): Promise<any> {
        const aliases: string[] = ["t", "i18n"];

        // Add aliases for "t" and "i18n" attributes
        TCustomAttribute.configureAliases(aliases);

        // Register i18next backend plugin
        instance.i18next.use(I18NextHttpBackend);

        // i18next options (see http://i18next.com/docs/options/)
        return instance.setup({
            backend: {
                loadPath: "./assets/{{lng}}.json?version=3.05.3000.02",
            },
            attributes: aliases,
            lng: "en",
            fallbackLng: ["en", "common"],
            preload: ["en", "fr", "common"],
            debug: Config.debug,
            nsSeparator: "|",
            parseMissingKeyHandler: (key: string): string => {
                Logger.warn(`I18N - Key not found: \"${key}\".`);
                return key;
            },
        });
    }

    private onAureliaStarted(): void {
        if (!browserHelper.localStorageAccess()) {
            this.aurelia.setRoot(PLATFORM.moduleName("pages/localStorageNotAccessible"));
            return;
        }

        this.localizationHelper.setLocale(this.settingRepository.getLanguage());

        // Redirect to the apropriate landing page
        if (!browserHelper.isSupported()) {
            this.aurelia.setRoot(PLATFORM.moduleName("pages/browserNotSupported"));
            return;
        }
        
        if (!userService.isLogged()) {
            val.init().done(() => {
                this.aurelia.setRoot(PLATFORM.moduleName("pages/login"));
            });

            return;
        }

        val.init().done(() => securityService.init().done(() => {
            applicationInsightsService.init();
            this.aurelia.setRoot(PLATFORM.moduleName("app"));
        }));
    }
}
